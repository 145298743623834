import * as React from "react";
import { EGDSFigure, EGDSFigureAspectRatioType } from "@egds/react-core/images";
import { EGDSLayoutFlex, EGDSLayoutFlexItem } from "@egds/react-core/layout-flex";

export interface HeroImageProps {
  src: string;
  className?: string;
}

export const HeroImage: React.FC<HeroImageProps> = ({ src, children, className = "" }) => (
  <EGDSLayoutFlex className="bexHeroImageBackground">
    <EGDSLayoutFlexItem>
      <EGDSFigure ratio={EGDSFigureAspectRatioType.R4_1} className={className} src={src}>
        {children}
      </EGDSFigure>
    </EGDSLayoutFlexItem>
  </EGDSLayoutFlex>
);
